import { combineReducers } from "redux";
import { useSelector, TypedUseSelectorHook } from "react-redux";

import auth from './auth/reducers'
import menu from './menu/reducers'
import order from './order/reducers'
import support from './support/reducers'
import waiter from './waiter/reducers'

import { AuthState } from "./auth/types";
import { MenuState } from "./menu/types";
import { OrderState } from "./order/types";
import { SupportState } from "./support/types";
import { WaiterState } from "./waiter/types";

interface RootState {
  auth: AuthState
  menu: MenuState
  order: OrderState
  support: SupportState
  waiter: WaiterState
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export default combineReducers({
  auth,
  menu,
  order,
  support,
  waiter
})