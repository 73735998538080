import { Action, AnyAction, applyMiddleware, legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkAction } from "redux-thunk";
import combineReducers from './rootReducer'

declare module 'redux' {
  interface Dispatch<A extends Action = AnyAction> {
    <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R;
  }
}

const initialState = {}

const middleware = [thunk]

const store = createStore(combineReducers, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store