import {
  POST_CALL_WAITER,
  WaiterActionType,
  WaiterState
} from "./types";

const initialState: WaiterState = {
  callWaiter: null
}

export default function (state= initialState, action: WaiterActionType): WaiterState {
  switch (action.type) {
    case POST_CALL_WAITER:
      return {
        ...state,
        callWaiter: action.payload
      }
    default:
      return state
  }
}