import { toast } from "react-hot-toast";
import { Transition } from "@headlessui/react";
import React from "react";

const ItemOutOfStock = (message: string) => toast.custom((t) => (
  <Transition
    appear
    show={t.visible}
    enter="transition-all duration-150"
    enterFrom="opacity-0 scale-50"
    enterTo="opacity-100 scale-100"
    leave="transition-all duration-150"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-75"
  >
    <div className="w-full min-h-14 bg-white shadow-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 rounded-lg">
      <div className="flex p-2.5">
        <div className="flex items-start">
          <img src={"/icon/toast/ToastWarning.svg"} alt="warning" className="my-auto"/>
          <div className="px-2.5 my-auto text-left">
            <p className="text-xs font-medium text-primary-black">Sorry, your selection stock is reduced</p>
            <p className="text-xxs text-secondary-black">There is reduced stock in {message}. Please check and update accordingly.</p>
          </div>
          <img
            src={"/icon/toast/ToastClose.svg"}
            alt="success"
            className="my-auto"
            onClick={() => toast.dismiss(t.id)}
          />
        </div>
      </div>
    </div>
  </Transition>
))

export default ItemOutOfStock;