export const GET_GUEST_TOKEN_SUCCESS = 'GET_GUEST_TOKEN_SUCCESS'
export const GET_GUEST_TOKEN_FAILED = 'GET_GUEST_TOKEN_FAILED'
export const GUEST_TOKEN_INVALID = 'GUEST_TOKEN_INVALID'

export interface Merchant {
  _id: string;
  email: string;
  hp: string;
  nama: string;
  deskripsi: string;
  website: string;
  icon: string;
  alamat: string;
  aktif: boolean;
  npwp: string;
  penanggungjawab: string;
  template: string;
  security_key: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface GuestToken {
  endpoint: string;
  token: string;
  meja: number;
  merchant: Merchant;
}

interface GetGuestTokenType {
  type: typeof GET_GUEST_TOKEN_SUCCESS,
  payload: GuestToken
}

interface GetGuestTokenFailedType {
  type: typeof GET_GUEST_TOKEN_FAILED
  payload: string
}

interface GuestTokenInvalidType {
  type: typeof GUEST_TOKEN_INVALID
  payload: boolean
}

export interface AuthState {
  guestToken: GuestToken
  tokenMessage: string
  isRedirectConfirmation: boolean
}

export type AuthActionType =
  | GetGuestTokenType
  | GetGuestTokenFailedType
  | GuestTokenInvalidType