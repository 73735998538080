import {
  GET_MY_ORDER,
  OrderActionType,
  OrderState,
  POST_CHECKOUT_ORDER,
  POST_CHECKOUT_STATUS,
  POST_SIMULATION_ORDER
} from "./types";

const initialState: OrderState = {
  simulation: null,
  checkout: null,
  status: '',
  myOrder: null
}

export default function (state= initialState, action: OrderActionType): OrderState {
  switch (action.type) {
    case POST_CHECKOUT_ORDER:
      return {
        ...state,
        checkout: action.payload
      }
    case POST_CHECKOUT_STATUS:
      return {
        ...state,
        status: action.payload
      }
    case POST_SIMULATION_ORDER:
      return {
        ...state,
        simulation: action.payload
      }
    case GET_MY_ORDER:
      return {
        ...state,
        myOrder: action.payload
      }
    default:
      return state
  }
}