export const GET_LIST_MENU = 'GET_LIST_MENU'
export const GET_LIST_SEARCH_MENU = 'GET_LIST_SEARCH_MENU'
export const GET_SELECTED_MENU = 'GET_SELECTED_MENU'
export const REMOVE_SELECTED_MENU = 'REMOVE_SELECTED_MENU'
export const CLEAR_SELECTED_MENU = 'CLEAR_SELECTED_MENU'
export const GET_LIST_CATEGORY = 'GET_LIST_CATEGORY'
export const GET_SELECTED_CATEGORY_NAME = 'GET_SELECTED_CATEGORY_NAME'
export const GET_LIST_MENU_BY_CATEGORY = 'GET_LIST_MENU_BY_CATEGORY'
export const CLEAR_ALL_MENU = 'CLEAR_ALL_MENU'
export const ITEM_STOCK = 'ITEM_STOCK'
export const IS_ITEM_LOADING = 'IS_ITEM_LOADING'
export const IS_CATEGORY_LOADING = 'IS_CATEGORY_LOADING'

export interface Gambar {
  size: string;
  source: string;
  _id: string;
  path: string;
  md5: string;
}

export interface Docs {
  _id: string;
  nama: string;
  kategori: string[];
  gambar: Gambar[];
  deskripsi: string;
  harga: number;
  stok: number;
  stock: number;
  merchant: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  aktif: boolean;
}

export interface ListMenu {
  docs: Docs[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null | number,
  nextPage: null | number,
}

export interface Icon {
  size: string;
  path: string;
  source: string;
  md5: string;
}

export interface ListCategory {
  _id: string;
  nama: string;
  icon: Icon;
  menu: any[];
  aktif: boolean;
  merchant: string;
  __v: number;
}

export interface SelectedMenu {
  [menuID: string]: {
    qty: number;
    catatan: string;
  }
}

interface GetListMenuType {
  type: typeof GET_LIST_MENU
  payload: ListMenu
}

interface GetListSearchMenuType {
  type: typeof GET_LIST_SEARCH_MENU
  payload: ListMenu
}

interface GetListCategoryType {
  type: typeof GET_LIST_CATEGORY
  payload: ListCategory[]
}

interface GetSelectedCategoryNameType {
  type: typeof GET_SELECTED_CATEGORY_NAME
  payload: string
}

interface GetListMenuByCategoryType {
  type: typeof GET_LIST_MENU_BY_CATEGORY
  payload: Docs[]
}

interface GetSelectedMenuType {
  type: typeof GET_SELECTED_MENU
  payloadMenu: Docs[]
  payloadPost: any
  payloadID: string[]
}

interface RemoveSelectedMenuType {
  type: typeof REMOVE_SELECTED_MENU
  payload: string
}

interface ClearSelectedMenuType {
  type: typeof CLEAR_SELECTED_MENU
}

interface ClearAllMenuType {
  type: typeof CLEAR_ALL_MENU
}

interface ItemStockType {
  type: typeof ITEM_STOCK
  payload: { [key: string]: number }
}

interface IsItemLoadingType {
  type: typeof IS_ITEM_LOADING
  payload: boolean
}

interface IsCategoryLoadingType {
  type: typeof IS_CATEGORY_LOADING
  payload: boolean
}

export interface MenuState {
  listCategory: ListCategory[]
  listMenu: ListMenu
  listSearchMenu: ListMenu
  listMenuByCategory: Docs[]
  selectedCategoryName: string
  selectedID: string[]
  selectedMenu: Docs[]
  selectedPost: any
  itemStock: { [key: string]: number }
  isItemLoading: boolean
  isCategoryLoading: boolean
}

export type MenuActionState =
  | GetListCategoryType
  | GetListMenuType
  | GetListMenuByCategoryType
  | GetListSearchMenuType
  | GetSelectedCategoryNameType
  | GetSelectedMenuType
  | RemoveSelectedMenuType
  | ClearSelectedMenuType
  | ClearAllMenuType
  | ItemStockType
  | IsItemLoadingType
  | IsCategoryLoadingType