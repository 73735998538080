import {
  SupportActionType,
  SupportState,
  IS_LOADING,
  MENU_TOOLTIP,
  TOGGLE_SLIDE_CART,
  TOGGLE_SLIDE_MENU,
  WAITER_TOOLTIP,
} from "./types";

const initialState: SupportState = {
  slideMenuShow: false,
  slideCartShow: false,
  menuTooltipShow: false,
  waiterTooltipShow: false,
  showTooltip: !!localStorage.getItem('showTooltip'),
  isLoading: false
}

export default function (state = initialState, action: SupportActionType): SupportState {
  switch (action.type) {
    case TOGGLE_SLIDE_MENU:
      return {
        ...state,
        slideMenuShow: action.payload
      }
    case TOGGLE_SLIDE_CART:
      return {
        ...state,
        slideCartShow: action.payload
      }
    case MENU_TOOLTIP:
      return {
        ...state,
        menuTooltipShow: action.payload
      }
    case WAITER_TOOLTIP:
      return {
        ...state,
        waiterTooltipShow: action.payload
      }
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state
  }
}