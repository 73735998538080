import React, { Fragment, Suspense, useEffect, useState } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from "./redux/store";
import ScrollToTop from './ScrollToTop';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import firebase from "./firebase";

import all_routes from "./routes";
import { Toaster } from 'react-hot-toast';
import { Transition } from "@headlessui/react";

function App() {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine)
  const [displayOnline, setDisplayOnline] = useState<boolean>(navigator.onLine)
  
  useEffect(() => {
    localStorage.setItem('endpoint', window.location.host.split('.')[0])
  }, [])
  
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setDisplayOnline(navigator.onLine);
      if (navigator.onLine) {
        setTimeout(() => {
          setIsOnline(navigator.onLine)
        }, 3000)
      } else {
        setIsOnline(navigator.onLine)
      }
    };
    
    // Listen to the online status
    window.addEventListener('online', handleStatusChange);
    
    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);
    
    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);
  
  return (
    <div className="App justify-center select-none">
      <div>
        <Toaster
          position="top-center"
          reverseOrder={true}
        />
      </div>
      <div className={"transform duration-500 top-0 sticky z-30 " + (isOnline ? "h-0" : "h-7")}>
        <Transition.Root show={!isOnline} as={Fragment}>
          <div className="z-30 top-0 sticky flex w-full">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="-translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-y-0"
              leaveTo="-translate-y-full"
            >
              <div className="pointer-events-auto relative w-screen">
                <div
                  className={"h-7 text-white text-center font-medium transform duration-700 " + (displayOnline ? 'bg-primary-green' : 'bg-primary-red')}>
                  <p className="py-0.5">{displayOnline ? 'Back Online' : 'No Connection are Available'}</p>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Transition.Root>
      </div>
      <Provider store={store}>
        <Router>
          <ScrollToTop/>
          <Suspense>
            <Routes>
              {all_routes.map(({Component, path}) => <Route
                key={path}
                path={path}
                element={<Component/>}
              />)}
            </Routes>
          </Suspense>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
