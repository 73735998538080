export const IS_LOADING = 'IS_LOADING'
export const MENU_TOOLTIP = 'MENU_TOOLTIP'
export const SHOW_TOOLTIP = 'SHOW_TOOLTIP'
export const TOGGLE_SLIDE_MENU = 'TOGGLE_SLIDE_MENU'
export const TOGGLE_SLIDE_CART = 'TOGGLE_SLIDE_CART'
export const WAITER_TOOLTIP = 'WAITER_TOOLTIP'

export type SlideBar = true | false

export interface ToggleSlideMenuType {
  type: typeof TOGGLE_SLIDE_MENU
  payload: SlideBar
}

export interface ToggleSlideCartType {
  type: typeof TOGGLE_SLIDE_CART
  payload: SlideBar
}

export interface MenuTooltipType {
  type: typeof MENU_TOOLTIP
  payload: boolean
}

export interface WaiterTooltipType {
  type: typeof WAITER_TOOLTIP
  payload: boolean
}

export interface ShowTooltipType {
  type: typeof SHOW_TOOLTIP
  payload: boolean
}

export interface IsLoadingType {
  type: typeof IS_LOADING
  payload: boolean
}

export interface SupportState {
  slideMenuShow: SlideBar
  slideCartShow: SlideBar
  menuTooltipShow: boolean
  waiterTooltipShow: boolean
  showTooltip: boolean
  isLoading: boolean
}

export type SupportActionType =
  | ToggleSlideMenuType
  | ToggleSlideCartType
  | MenuTooltipType
  | WaiterTooltipType
  | ShowTooltipType
  | IsLoadingType