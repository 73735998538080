import {
  AuthActionType,
  AuthState,
  GET_GUEST_TOKEN_FAILED,
  GET_GUEST_TOKEN_SUCCESS,
  GUEST_TOKEN_INVALID
} from "./types";

const initialState: AuthState = {
  guestToken: JSON.parse(localStorage.getItem('guestToken') || '{}'),
  tokenMessage: '',
  isRedirectConfirmation: false
}

export default function (state= initialState, action: AuthActionType): AuthState {
  switch (action.type) {
    case GET_GUEST_TOKEN_SUCCESS:
      if (Object.keys(action.payload).length > 0) {
        localStorage.setItem('token', action.payload.token)
        localStorage.setItem('endpoint', action.payload.endpoint)
      }
      localStorage.setItem('guestToken', JSON.stringify(action.payload))
      return {
        ...state,
        guestToken: action.payload,
        tokenMessage: ''
      }
    case GET_GUEST_TOKEN_FAILED:
      localStorage.clear()
      return {
        ...state,
        guestToken: JSON.parse('{}'),
        tokenMessage: action.payload
      }
    case GUEST_TOKEN_INVALID:
      return {
        ...state,
        isRedirectConfirmation: action.payload
      }
    default:
      return state
  }
}