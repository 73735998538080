import { lazy } from "react";

const all_routes = [
  {
    path: '/',
    title: 'Homepage',
    Component: lazy(() => import('./component/Homepage/Homepage'))
  },
  {
    path: '/category',
    title: 'Category',
    Component: lazy(() => import('./component/Homepage/Category'))
  },
  {
    path: '/category/:id',
    title: 'Single Category',
    Component: lazy(() => import('./component/Homepage/SingleCategory'))
  },
  {
    path: '/checkout',
    title: 'Checkout',
    Component: lazy(() => import('./component/Checkout/Checkout'))
  },
  {
    path: '/confirmation',
    title: 'Confirmation',
    Component: lazy(() => import('./component/Checkout/Confirmation'))
  },
  {
    path: '/checkout-success',
    title: 'Checkout Success',
    Component: lazy(() => import('./component/Checkout/CheckoutSuccess'))
  },
  {
    path: '/my-order',
    title: 'My Order',
    Component: lazy(() => import('./component/Order/MyOrder'))
  }
]

export default all_routes