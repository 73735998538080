import {
  CLEAR_ALL_MENU,
  CLEAR_SELECTED_MENU,
  GET_LIST_CATEGORY,
  GET_LIST_MENU,
  GET_LIST_MENU_BY_CATEGORY,
  GET_LIST_SEARCH_MENU,
  GET_SELECTED_CATEGORY_NAME,
  GET_SELECTED_MENU,
  IS_CATEGORY_LOADING,
  IS_ITEM_LOADING,
  ITEM_STOCK,
  REMOVE_SELECTED_MENU,
  MenuActionState,
  MenuState,
} from "./types";
import ItemOutOfStock from "../../component/Toast/ItemOutOfStock";

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 0,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const initialState: MenuState = {
  listCategory: [],
  listMenu: defaultList,
  listMenuByCategory: [],
  listSearchMenu: defaultList,
  selectedCategoryName: '',
  selectedMenu: JSON.parse(localStorage.getItem('selectedMenu') || '[]'),
  selectedPost: JSON.parse(localStorage.getItem('selectedPost') || '{}'),
  selectedID: JSON.parse(localStorage.getItem('selectedID') || '[]'),
  itemStock: {},
  isItemLoading: false,
  isCategoryLoading: false
}

export default function (state= initialState, action: MenuActionState): MenuState {
  switch (action.type) {
    case GET_LIST_MENU:
      return {
        ...state,
        listMenu: action.payload
      }
    case ITEM_STOCK:
      let newState = {
        ...state,
        itemStock: action.payload
      }
      if (state.selectedMenu.length > 0) {
        const temp = [...state.selectedMenu]
        const selectedTemp = {...state.selectedPost}
        for (let i in temp) {
          if (action.payload.hasOwnProperty(temp[i]._id)) {
            temp[i].stock = action.payload[temp[i]._id]
            if (selectedTemp[temp[i]._id].qty > action.payload[temp[i]._id]) {
              ItemOutOfStock(temp[i].nama)
            }
          }
        }
        localStorage.setItem('selectedMenu', JSON.stringify(temp))
        newState.selectedMenu = temp
      }
      return newState
    case GET_LIST_SEARCH_MENU:
      return {
        ...state,
        listSearchMenu: action.payload
      }
    case GET_LIST_CATEGORY:
      return  {
        ...state,
        listCategory: action.payload
      }
    case GET_SELECTED_CATEGORY_NAME:
      return {
        ...state,
        selectedCategoryName: action.payload
      }
    case GET_LIST_MENU_BY_CATEGORY:
      return {
        ...state,
        listMenuByCategory: action.payload
      }
    case GET_SELECTED_MENU:
      if (state.selectedID.includes(action.payloadID.toString())) {
        const tempPost = {...state.selectedPost, ...action.payloadPost}
        localStorage.setItem('selectedPost', JSON.stringify(tempPost))
        return {
          ...state,
          selectedPost: tempPost
        }
      } else {
        const tempMenu = state.selectedMenu.concat(action.payloadMenu)
        const tempPost = {...state.selectedPost, ...action.payloadPost}
        const tempID = state.selectedID.concat(action.payloadID)
        
        localStorage.setItem('selectedMenu', JSON.stringify(tempMenu))
        localStorage.setItem('selectedPost', JSON.stringify(tempPost))
        localStorage.setItem('selectedID', JSON.stringify(tempID))
        
        return {
          ...state,
          selectedMenu: tempMenu,
          selectedPost: tempPost,
          selectedID: tempID
        }
      }
    case REMOVE_SELECTED_MENU:
      const tempMenu = state.selectedMenu.filter(item => { return item._id !== action.payload})
      const tempPost = state.selectedPost
      const tempID = state.selectedID
      
      const index = tempID.indexOf(action.payload)
      if (index > -1) {
        tempID.splice(index, 1)
      }
      delete tempPost[action.payload]
  
      localStorage.setItem('selectedMenu', JSON.stringify(tempMenu))
      localStorage.setItem('selectedPost', JSON.stringify(tempPost))
      localStorage.setItem('selectedID', JSON.stringify(tempID))
      
      return {
        ...state,
        selectedMenu: tempMenu,
        selectedPost: tempPost,
        selectedID: tempID
      }
    case CLEAR_SELECTED_MENU:
      localStorage.setItem('selectedMenu', JSON.stringify([]))
      localStorage.setItem('selectedPost', JSON.stringify({}))
      localStorage.setItem('selectedID', JSON.stringify([]))
      return {
        ...state,
        selectedMenu: [],
        selectedPost: {},
        selectedID: []
      }
    case CLEAR_ALL_MENU:
      return {
        ...state,
        listCategory: [],
        listMenu: defaultList,
        listMenuByCategory: [],
        selectedCategoryName: '',
      }
    case IS_ITEM_LOADING:
      if (state.isItemLoading !== action.payload) {
        return {
          ...state,
          isItemLoading: action.payload
        }
      } else {
        return state
      }
    case IS_CATEGORY_LOADING:
      if (state.isCategoryLoading !== action.payload) {
        return {
          ...state,
          isCategoryLoading: action.payload
        }
      } else {
        return state
      }
    default:
      return state
  }
}