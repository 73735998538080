export const POST_CALL_WAITER = 'POST_CALL_WAITER'

export interface Waiter {
  meja: string
}

interface PostCallWaiterTypes {
  type: typeof POST_CALL_WAITER
  payload: Waiter
}

export interface WaiterState {
  callWaiter: Waiter | null
}

export type WaiterActionType =
  | PostCallWaiterTypes