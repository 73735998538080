import { Docs } from "../menu/types";

export const POST_SIMULATION_ORDER = 'POST_SIMULATION_ORDER'
export const POST_CHECKOUT_ORDER = 'POST_CHECKOUT_ORDER'
export const POST_CHECKOUT_STATUS = 'POST_CHECKOUT_STATUS'
export const GET_MY_ORDER = 'GET_MY_ORDER'

export interface ItemSimulation {
  qty: number;
  subtotal: number;
  catatan: string;
  status: string;
  menu: string;
  catatan_kitchen: string;
}

export interface Simulation {
  subtotal: number;
  discount: number;
  total: number;
  service: number;
  servicePercentage: number;
  tax: number;
  taxPercentage: number;
  items: ItemSimulation[];
  status: string;
  timestamp: string;
  _id: string
}

export interface ItemCheckout {
  menu: Docs;
  qty: number;
  subtotal: number;
  catatan: string;
  status: string;
  catatan_kitchen: string;
  _id: string;
}

export interface OrderCheckout {
  items: ItemCheckout[];
  nama: string;
  status: string;
  terbayar: boolean;
  timestamp: string;
  total: number;
  _id: string;
}

export interface Staff {
  _id: string;
  email: string;
  hp: string;
  nama: string;
  aktif: boolean;
  merchant: string;
  roles: string[];
  __v: number;
  fcm_token: string;
}

export interface Checkout {
  meja: number;
  subtotal: number;
  discount: number;
  total: number;
  service: number;
  servicePercentage: number;
  tax: number;
  taxPercentage: number;
  orders: OrderCheckout[];
  merchant: string;
  staff: Staff[];
  status: string;
  id: string
  _id: string;
  __v: number;
}

export interface MyOrder extends Checkout {
  pembayaran_status: string;
  createdAt: Date;
  updatedAt: Date;
}

interface PostSimulationOrderTypes {
  type: typeof POST_SIMULATION_ORDER
  payload: Simulation
}

interface PostCheckoutOrderTypes {
  type: typeof POST_CHECKOUT_ORDER
  payload: Checkout
}

interface PostCheckoutStatusTypes {
  type: typeof POST_CHECKOUT_STATUS
  payload: string
}

interface GetMyOrderTypes {
  type: typeof GET_MY_ORDER
  payload: MyOrder
}

export interface OrderState {
  simulation: Simulation | null
  checkout: Checkout | null
  status: string
  myOrder: MyOrder | null
}

export type OrderActionType =
  | PostSimulationOrderTypes
  | PostCheckoutOrderTypes
  | PostCheckoutStatusTypes
  | GetMyOrderTypes